jQuery(document).ready(function($) {
  // ------ Init ------ //
  $("#cubeLoad").animate({
    opacity: "toggle"
  },1200);

  AOS.init();
  // ------ Sticky Nav ------ //
  $(window).scroll(function() {
    var scroll = $(window).scrollTop();
    // move callback button //
    if (scroll >= 1150) {
      $('#stickyCubeNav').addClass('scrollheaderCB_enabled');
    } else {
      $('#stickyCubeNav').removeClass('scrollheaderCB_enabled');
    }
  });
  // ------ Animations ------ //
  // magic //
  var controller = new ScrollMagic.Controller();
  // all devs animations //
  if ($("#cube_developmentsCaro").length) {
    var alldevsicon = TweenMax.fromTo(".iconFlldevSlide", 1, { top: '-155px', right: '-115px' }, { top: '-195px' , right: '-155px' , ease: Power0.linear}  );
    var scene = new ScrollMagic.Scene({
        triggerElement: "#cube_developmentsCaro",
        duration: 1100
      })
    .setTween(alldevsicon)
    // .addIndicators({name: "Move all devs icon logo"})
    .addTo(controller);
  }
  //  about us block //
  if ($("#topAboutContent").length) {
    if($(window).width() > 998) {
      // logo move //
      var alldevsicon = TweenMax.fromTo(".iconFllattVid", 1, { bottom: '-150px', right: '-100px' }, { bottom: '-95px' , right: '-135px' , ease: Power0.linear}  );
      var scene = new ScrollMagic.Scene({
          triggerElement: "#topAboutContent",
          duration: 1280,
          offset: 475
        })
      .setTween(alldevsicon)
      // .addIndicators({name: "Move about icon logo"})
      .addTo(controller);
      // white bg //
      var alldevsicon = TweenMax.fromTo(".leftWrap", 1, { marginTop: '25px' }, { marginTop: '275px', ease: Power0.linear}  );
      var scene = new ScrollMagic.Scene({
          triggerElement: "#topAboutContent",
          duration: 998,
          // offset: 475
        })
      .setTween(alldevsicon)
      // .addIndicators({name: "Move about bg"})
      .addTo(controller);
    }
  }
  // our promise block //
  if ($("#maincopy_area_opy").length) {
    if($(window).width() > 998) {
      // logo move //
      var alldevsicon = TweenMax.fromTo(".iconFloatMid", 1, { right: '-215px', top: '55%' }, { right: '-275px' , top: '47%' , ease: Power0.linear}  );
      var scene = new ScrollMagic.Scene({
          triggerElement: "#maincopy_area_opy",
          duration: 2225,
          offset: 100
        })
      .setTween(alldevsicon)
      // .addIndicators({name: "Move opty icon logo"})
      .addTo(controller);
    }
  }
  // home  about us block //
  if ($("#cube_aboutUsBlock").length) {
    if($(window).width() > 998) {
      var setupmovement = TweenMax.fromTo(".mainContentWrap", 1, { top: '0' }, { top: '-205px', ease: Power0.linear}  );
      var scene = new ScrollMagic.Scene({
          triggerElement: "#cube_aboutUsBlock",
          duration: 1175,
          // offset: 475
        })
      .setTween(setupmovement)
      // .addIndicators({name: "Move section"})
      .addTo(controller);
      // play button //
      var playbtnicon = TweenMax.fromTo(".vidControl", 1, { bottom: '-50px' }, { bottom: '0' , ease: Power0.linear}  );
      var scene = new ScrollMagic.Scene({
          triggerElement: "#cube_aboutUsBlock",
          duration: 750,
          offset: 215
        })
      .setTween(playbtnicon)
      // .addIndicators({name: "Move about icon logo"})
      .addTo(controller);
      // testimonials //
      var testimonvebdown = TweenMax.fromTo(".tmonialWrap", 1, { marginBottom: '50px' }, { marginBottom: '10px' , ease: Power0.linear}  );
      var scene = new ScrollMagic.Scene({
          triggerElement: "#cube_aboutUsBlock",
          duration: 750,
          offset: 215
        })
      .setTween(testimonvebdown)
      // .addIndicators({name: "Move about icon logo"})
      .addTo(controller);
      // circle //
      var aboutCircleMove = TweenMax.fromTo(".topQualtyCirc", 1, { top: '-70px', right: '0' }, { top: '-15px' , right: '10px' , ease: Power0.linear}  );
      var scene = new ScrollMagic.Scene({
          triggerElement: "#cube_aboutUsBlock",
          duration: 1100,
          offset: 10
        })
      .setTween(aboutCircleMove)
      // .addIndicators({name: "Move about circlee logo"})
      .addTo(controller);
    }
  }
  // latest news //
  if ($("#cube_latestNews").length) {
    if($(window).width() > 998) {
      var setupmovement = TweenMax.fromTo(".imgWrapR", 1, { marginBottom: '-100px' }, { marginBottom: '0', ease: Power0.linear}  );
      var scene = new ScrollMagic.Scene({
          triggerElement: "#cube_latestNews",
          duration: 1375,
          // offset: 475
        })
      .setTween(setupmovement)
      // .addIndicators({name: "Move section news"})
      .addTo(controller);
    }
  }
  // dev banner //
  if ($("#devht_bannerInfo").length) {
    if($(window).width() > 998) {
      var setupmovement = TweenMax.fromTo(".infoPopWrap", 1, { top: '0' }, { top: '-80px', ease: Power0.linear}  );
      var scene = new ScrollMagic.Scene({
          triggerElement: "#devht_bannerInfo",
          duration: 1500,
          offset: 0.5
        })
      .setTween(setupmovement)
      // .addIndicators({name: "Move top dev"})
      .addTo(controller);
      var setupmovement = TweenMax.fromTo(".rightinfo", 1, { marginRight: '95px' }, { marginRight: '115px', ease: Power0.linear}  );
      var scene = new ScrollMagic.Scene({
          triggerElement: "#devht_bannerInfo",
          duration: 1200,
          offset: 0.5
        })
      .setTween(setupmovement)
      // .addIndicators({name: "Move top dev"})
      .addTo(controller);
    }
  }
  // dev location map //
  if ($("#dev_location_cb").length) {
    if($(window).width() > 998) {
      var setupmovement = TweenMax.fromTo(".rightImgWrap", 1, { marginTop: '125px', marginRight: '-125px' }, { marginTop: '45px', marginRight: '-145px', ease: Power0.linear}  );
      var scene = new ScrollMagic.Scene({
          triggerElement: "#dev_location_cb",
          duration: 1425,
          // offset: 475
        })
      .setTween(setupmovement)
      // .addIndicators({name: "Move section news"})
      .addTo(controller);
    }
  }
  //  contact us block //
  if ($("#topDetails").length) {
    if($(window).width() > 768) {
      // logo move //
      var alldevsicon = TweenMax.fromTo(".iconFllatCff", 1, { bottom: '50px', right: '-50px' }, { bottom: '105px' , right: '-10px' , ease: Power0.linear}  );
      var scene = new ScrollMagic.Scene({
          triggerElement: "#topDetails",
          duration: 1480,
          offset: 475
        })
      .setTween(alldevsicon)
      // .addIndicators({name: "Move about icon logo"})
      .addTo(controller);
    }
  }
  // ------ Click Events ------ //
  // hamburger //
  $('.toggle-containHdr').click(function() {
    $('body').addClass('noscrollerBd');
    $('#hmb_overlay').addClass('open');
    // headers //
    $('#stickyCubeNav').removeClass('scrollheaderCB_enabled');
    $('#cube_siteHdr').addClass('hidemainHdr');
  });
  $('.closeOverlayerMenu').click(function() {
    $('body').removeClass('noscrollerBd');
    $('#hmb_overlay').removeClass('open');
    // headers //
    $('#stickyCubeNav').removeClass('scrollheaderCB_enabled');
    $('#cube_siteHdr').removeClass('hidemainHdr');
  });
  // menu option swapper //
  $('.our-dev-overlaytoggle-cb').click(function(e) {
    $('.cube-main-menuHb').addClass('hidethis-menu-typecube').removeClass('showthis-menu-typecube');
    $('.cube-develop-menuHb').removeClass('hidethis-menu-typecube').addClass('showthis-menu-typecube');
    $('.endLinks').hide();
    e.preventDefault();
  });
  $('#return-main-dev-cb').click(function(e) {
    $('.cube-main-menuHb').removeClass('hidethis-menu-typecube').addClass('showthis-menu-typecube');
    $('.cube-develop-menuHb').addClass('hidethis-menu-typecube').removeClass('showthis-menu-typecube');
    $('.endLinks').show();
    e.preventDefault();
  });
  // video embed //
  $('.plembedvideo').click(function() {
    $('body').addClass('noscrollerBd');
    $('#videoOverlayerCb').addClass('activatedOverlayerCB');
    gtag('event', 'conversion', { event_category: 'Video Play Button Click', event_action: 'click'});
  });
  $('.closeOverlayerVid').click(function() {
    $('body').removeClass('noscrollerBd');
    $('#videoOverlayerCb').removeClass('activatedOverlayerCB');
    $('#cubeEmbedVideo').each(function(index) {
      $(this).attr('src', $(this).attr('src'));
      return false;
    });
  });
  // sitemap key mobile //
  $('.viewspMobbl').click(function() {
    $('.siteplan_mobile').toggleClass('show_mobile_sp');
  });
  // find home view types //
  $('.tabswitcher').click(function() {
    var type = $(this).attr('data-swtichtype');
    $('.tabswitcher').removeClass('activetabswtich');
    $(this).addClass('activetabswtich');
    if(type == 'listviewcube') {
      $('#lisview_elements').removeClass('hidethisviewtype');
      $('#mapviews_elements').addClass('hidethisviewtype');
    }
    else {
      $('#mapviews_elements').removeClass('hidethisviewtype');
      $('#lisview_elements').addClass('hidethisviewtype');
      gtag('event', 'conversion', { event_category: 'Map View Select Click', event_action: 'click'});
    }
  });
  // house type availability //
  $('.openFormOverlayEnqHT').click(function() {
    $('body').addClass('noscrollerBd');
    $('#hts-form-overlay').addClass('activeDevFormOverlay');
    // populate plot //
    var plothousetype = $(this).attr('data-htact');
    var plotactive = $(this).attr('data-plotht');
    $('#htplotfield').val('Plot '+plotactive);
    $('#httypepl').val(plothousetype);
  });
  $('.close_DevCb').click(function() {
    $('body').removeClass('noscrollerBd');
    // reset some defaults //
    $('#hts-form-overlay').removeClass('activeDevFormOverlay');
    $('#htplotfield').val('');
  });
  // ------ Owl ------ //
  // home banner //
  $('#cube_homebnr').owlCarousel({
    loop:true,
    nav:false,
    dots:true,
    items: 1,
    autoplay:true,
    autoplayTimeout:9000,
    animateOut: 'fadeOut',
    // animateIn: 'fadeIn'
  });
  var homeSliderAnimate = $('#cube_homebnr');
  homeSliderAnimate.owlCarousel();
  homeSliderAnimate.on('changed.owl.carousel', function(e) {
    homeSliderAnimate.trigger('stop.owl.autoplay');
    homeSliderAnimate.trigger('play.owl.autoplay');
  });
  // dev banner //
  $('#allDevsSlider').owlCarousel({
    nav:false,
    dots:false,
    margin:15,
    items: 2,
    animateOut: 'fadeOut',
  });
  var cubedevgal = $('#allDevsSlider');
  cubedevgal.owlCarousel();
  $('.movealldevright').click(function() {
    cubedevgal.trigger('next.owl.carousel');
  })
  $('.movealldevleft').click(function() {
    cubedevgal.trigger('prev.owl.carousel');
  });
  // general gallery banner //
  $('#cubeGalleyMnn').owlCarousel({
    nav:false,
    dots:false,
    margin:15,
    items: 2,
    responsive:{
      0:{
        items:1,
      },
      768:{
        items:2
      }
    },
    animateOut: 'fadeOut',
  });
  var cubemaingallery = $('#cubeGalleyMnn');
  cubemaingallery.owlCarousel();
  $('.movemaingalright').click(function() {
    cubemaingallery.trigger('next.owl.carousel');
  })
  $('.movemaingallft').click(function() {
    cubemaingallery.trigger('prev.owl.carousel');
  });
  // floorplans //
  $('#floorplansCaroHt').owlCarousel({
    loop:false,
    nav:false,
    dots:false,
    items: 1,
    animateOut: 'fadeOut',
    // animateIn: 'fadeIn'
  });
  var cubefpgal = $('#floorplansCaroHt');
  cubefpgal.owlCarousel();
  $('.movefloorpllsright').click(function() {
    cubefpgal.trigger('next.owl.carousel');
  })
  $('.movefloorpllsleft').click(function() {
    cubefpgal.trigger('prev.owl.carousel');
  });
  // testimonials //
  $('#testimonialsAbtSlidd').owlCarousel({
    loop:false,
    nav:false,
    dots:true,
    items: 1,
    animateOut: 'fadeOut',
    // animateIn: 'fadeIn'
  });
  // ------ Tabbed Changing ------ //
  $('#spTabs li a:not(:first)').addClass('inactive');
  $('.sp_content_wrap').hide();
  $('.sp_content_wrap:first').show();
  $('.sp_content_wrapSpec').hide();
  $('.sp_content_wrapSpec:first').show();
  $('#spTabs li a').click(function(){
    var t = $(this).attr('href');
    $('#spTabs li a').addClass('inactive');
    $(this).removeClass('inactive');
    $('.sp_content_wrap').hide();
    $('.sp_content_wrapSpec').hide();
    $(t).fadeIn(800);
    return false;
  })
  if($(this).hasClass('inactive')){ //this is the start of our condition
    $('#spTabs li a').addClass('inactive');
    $(this).removeClass('inactive');
    $('.sp_content_wrap').hide();
    $('.sp_content_wrapSpec').hide();
    $(t).fadeIn(650);
  }
  // mobile version //
  $('#specSelection').change(function() {
    var t = $(this).val();
    $('#spTabs li a').addClass('inactive');
    $(this).removeClass('inactive');
    $('.sp_content_wrap').hide();
    $('.sp_content_wrapSpec').hide();
    $(t).fadeIn(800);
    return false;
    if($(this).hasClass('inactive')){ //this is the start of our condition
      $('#spTabs li a').addClass('inactive');
      $(this).removeClass('inactive');
      $('.sp_content_wrap').hide();
      $('.sp_content_wrapSpec').hide();
      $(t).fadeIn(650);
    }
  });
  // ------ Siteplan ------ //
  // START populate sidebar in numerical order //
  var element =  document.getElementById('siteplan_wrap');
  if (typeof(element) != 'undefined' && element != null) {
    window.panZoom = svgPanZoom('#siteplan_wrap svg', {
      zoomEnabled: true,
      mouseWheelZoomEnabled: false,
      controlIconsEnabled: false,
      zoomScaleSensitivity: 0.25,
      maxZoom: 3,
      minZoom: 1.25,
      contain: true
    });
    // Zoom by 125% on intialise //
    panZoom.zoomBy(1.25);
    // Zoom in //
    document.getElementById('zoom-in').addEventListener('click', function(ev){
      ev.preventDefault();
      jQuery('#interactive_infopopup').removeClass('showinteractive_infopop');
      panZoom.zoomIn();
      // Zoom vars //
      var currenrtZoom = panZoom.getZoom();
      var highestZoom = Math.round(currenrtZoom);
      var lowestZoom = currenrtZoom.toFixed(3);
      // Max zoom in hit - disable class btn //
      if(highestZoom === 3) {
        jQuery(this).addClass('inactiv_StylSp');
      }
      // you can zoom out so make sure style reps that //
      jQuery('#zoom-out').removeClass('inactiv_StylSp');
    });
    // Zoom out //
    document.getElementById('zoom-out').addEventListener('click', function(ev){
      ev.preventDefault();
      jQuery('#interactive_infopopup').removeClass('showinteractive_infopop');
      panZoom.zoomOut();
      // Zoom vars //
      var currenrtZoom = panZoom.getZoom();
      var highestZoom = Math.round(currenrtZoom);
      var lowestZoom = currenrtZoom.toFixed(3);
      // Min zoom in hit - disable class btn //
      if(lowestZoom == 1.250) {
        jQuery(this).addClass('inactiv_StylSp');
      }
      // you can zoom in so make sure style reps that //
      jQuery('#zoom-in').removeClass('inactiv_StylSp');
    });
  }
  // Prevent Info window staying around on drag //
  $('#siteplan_wrap svg').on('click',function() {
    $('#interactive_infopopup').removeClass('showinteractive_infopop');
  });
  // END Prevent Info window staying around on drag //
  // START - Show infowindow aJax call //
  $('#Housetypes polygon, path, rect').on('click tap touchstart',function() {
    // get current dev for backup //
    var devIdDD = $('#siteplan_wrap').attr("par-id");
    // get current position //
    var x = $(this).position();
    var changeTop = x.top - 290;
    var changeLeft = x.left - 130;
    // get ID associated with HT //
    var houseTypeInfo = $(this).attr("id");
    // check if house type has an entry/SVG ID to split //
    if(typeof houseTypeInfo !== 'undefined') {
      // var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      // split it up //
      var houseTypeInfoSplit = houseTypeInfo.split(/[-]/);
      // house type vars //
      var thisHTname = houseTypeInfoSplit[0];
      var thisHTid = houseTypeInfoSplit[1];
      var thisHTplotID = houseTypeInfoSplit[2];

      // leave out affordable housing //
      if(thisHTname == 'affordable' && thisHTid == 'housing') {
      }
      else {
        // Ajax for changing popup info //
        var contents = {
          action: 'inter_map_deets',
          housetypeID:	thisHTid,
          htplotID: thisHTplotID,
          devParentID:  devIdDD
        };
        $.ajax({
            type: 'POST',
            url: theme_ajax.url,
            data: contents,
            beforeSend: function() {
              $('#interactive_infopopup').removeClass('showinteractive_infopop');
            },
            success: function(data) {
              // hide controls //
              $('#sp_controls').addClass('hideSPmcCnrtls');
              $('.mobBtns').addClass('hidemobBtnComplete');
              // console.log(data);
              var clickSitemap = JSON.parse(data);
              $('#interactive_infopopup').html(clickSitemap.content);
              // Change info window poisiton //
              $('#interactive_infopopup').css({
                'top' : changeTop,
                'left' : changeLeft
              });
              // show it //
              $('#interactive_infopopup').addClass('showinteractive_infopop');
              // Close infowindow down  //
              $('#close_infowHt').on('click tap touchstart',function(){
                // bring back controlls //
                $('#sp_controls').removeClass('hideSPmcCnrtls');
                $('.mobBtns').removeClass('hidemobBtnComplete');
                $('#interactive_infopopup').removeClass('showinteractive_infopop');
              });
            },
            error: function(xhr) { // if error occured
            }
        });
      }
    }
  });
  // END - Show infowindow aJax call //
  // START Mobile/tablet toggles //
  $('#mobKeyToggle').on('click tap touchstart',function(e) {
    $(this).html() == "Show Key" ? $(this).html('Hide Key') : $(this).html('Show Key');
    $("#siteplan_key").toggleClass("showMobileKeyMcc");
    e.preventDefault();
  });
  // END Mobile/tablet toggles //
  /* ====================== Request Callback ======================  */
  $('#request_btn_callback').click(function() {
  	$(this).addClass('activeRequestFormBtn');
  	$('body').addClass('noscrollerBd');
  	$('#callBack_RequestWrap').addClass('activeRequestFormOverlay');
  });
  $('.openRequestFromBc').click(function() {
  	$('#request_btn_callback').addClass('activeRequestFormBtn');
  	$('body').addClass('noscrollerBd');
  	$('#callBack_RequestWrap').addClass('activeRequestFormOverlay');
  });
  $('.close_ReqCb').click(function() {
  	$('#request_btn_callback').removeClass('activeRequestFormBtn');
  	$('body').removeClass('noscrollerBd');
  	$('#callBack_RequestWrap').removeClass('activeRequestFormOverlay');
  });
  $(window).scroll(function() {
  	var scroll = $(window).scrollTop();
  	// move callback button //
  	if (scroll >= 1500) {
  		 $('#request_btn_callback').addClass('activeRequestFormBtn');
  	} else {
  		 $('#request_btn_callback').removeClass('activeRequestFormBtn');
  	}
  });
  /* ====================== Accordion/collapser ======================  */
  $('.closingSign').on('click',function () {
    $(".stepWrapp" ).removeClass("activatedStepWrapp");
    $(".closingSign" ).addClass("collsdSteppEntry");
    $(this).toggleClass('collsdSteppEntry')
    $(this ).parent( ".stepWrapp" ).toggleClass("activatedStepWrapp");
    AOS.refresh();
  });
  // CF7//
  document.addEventListener( 'wpcf7invalid', function( event ) {
    $('.wpcf7-response-output').removeClass('alert-form alertform-warning');
    $('.wpcf7-response-output').addClass('alert-form alertform-danger');
  }, false );
  document.addEventListener( 'wpcf7spam', function( event ) {
    $('.wpcf7-response-output').removeClass('alert-form alertform-danger');
    $('.wpcf7-response-output').addClass('alert-form alertform-warning');
  }, false );
  document.addEventListener( 'wpcf7mailfailed', function( event ) {
    $('.wpcf7-response-output').removeClass('alert-form alertform-danger');
    $('.wpcf7-response-output').addClass('alert-form alertform-warning');
  }, false );
  document.addEventListener( 'wpcf7mailsent', function( event ) {
    $('.wpcf7-response-output').removeClass('alert-form alertform-danger');
    $('.wpcf7-response-output').removeClass('alert-form alertform-warning');
    $('.wpcf7-response-output').addClass('alert-form alertform-success');
  }, false );
});
