/* ====================== Vars ======================  */
  var gmarkers = [];
  var map = null;
/* ====================== Search Markers ======================  */

function createMarker(latlng,link,title,image,location,minbed,maxbed) {
  var contentString =
  '<div class="featureImg" style="background-image:url('+image+');"></div>'+
  '<div class="txtWrapper">'+
    '<h4>'+ title + ' <br></h4>'+
    '<p><span>'+ location +'</span> '+ maxbed +' & '+ minbed +' Bedroom homes</p>'+
    '<a href="'+link+'" class="red_btn_small">View development</a>'+
  '</div>';
  // marker setup //
  var marker = new google.maps.Marker({
    position: latlng,
    map: map,
    link: link,
    title: title,
    icon: '/wp-content/themes/cubehomes/library/images/icons/current-map-icon.svg',
    //name: name,
    zIndex: Math.round(latlng.lat()*-100000)<<5
  });
  // infowindow //
  google.maps.event.addListener(marker, 'click', function() {
     infowindowRad.setContent(contentString);
     infowindowRad.open(map,marker);
   });
  // Save Marker //
  gmarkers.push(marker);
}
/* ====================== Create Map Markers ======================  */
function codeAddress() {
  var foundMarkers = 0;
  var foundMarkerSetup = []; // setup blank array for new sort //
  for (var i=0; i<gmarkers.length;i++) {
    // setup marker //
    (gmarkers[i].getPosition())
    gmarkers[i].setMap(map);
    // run and repeat //
    foundMarkers++;
  }
}
/* ====================== Create Map ======================  */
// style map //
var greyscalemap = [
{
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#f5f5f5"
    }
  ]
},
{
  "elementType": "labels.icon",
  "stylers": [
    {
      "visibility": "off"
    }
  ]
},
{
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#616161"
    }
  ]
},
{
  "elementType": "labels.text.stroke",
  "stylers": [
    {
      "color": "#f5f5f5"
    }
  ]
},
{
  "featureType": "administrative.land_parcel",
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#bdbdbd"
    }
  ]
},
{
  "featureType": "poi",
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#eeeeee"
    }
  ]
},
{
  "featureType": "poi",
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#757575"
    }
  ]
},
{
  "featureType": "poi.park",
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#e5e5e5"
    }
  ]
},
{
  "featureType": "poi.park",
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#9e9e9e"
    }
  ]
},
{
  "featureType": "road",
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#ffffff"
    }
  ]
},
{
  "featureType": "road.arterial",
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#757575"
    }
  ]
},
{
  "featureType": "road.highway",
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#dadada"
    }
  ]
},
{
  "featureType": "road.highway",
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#616161"
    }
  ]
},
{
  "featureType": "road.local",
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#9e9e9e"
    }
  ]
},
{
  "featureType": "transit.line",
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#e5e5e5"
    }
  ]
},
{
  "featureType": "transit.station",
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#eeeeee"
    }
  ]
},
{
  "featureType": "water",
  "elementType": "geometry",
  "stylers": [
    {
      "color": "#c9c9c9"
    }
  ]
},
{
  "featureType": "water",
  "elementType": "labels.text.fill",
  "stylers": [
    {
      "color": "#9e9e9e"
    }
  ]
}
];
function initializeMap() {
  var radiusMap = {
    zoom: 11,
    center: new google.maps.LatLng(53.52807891965501, -2.3108346518470384),
    // mapTypeControl: true,
    // mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
    navigationControl: true,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: greyscalemap
  }
  map = new google.maps.Map(document.getElementById("mapviews_elements"), radiusMap);
  // Read the XML data using function in download_xml.js - downloadURL()
 downloadUrl("../../wp-content/uploads/map-markers/markers.xml", function(doc) {
    var xmlDoc = xmlParse(doc);
    var markers = xmlDoc.documentElement.getElementsByTagName("marker");
    for (var i = 0; i < markers.length; i++) {
      // position //
      var lat = parseFloat(markers[i].getAttribute("lat"));
      var lng = parseFloat(markers[i].getAttribute("lng"));
      var point = new google.maps.LatLng(lat,lng);
      // information //
      var link = markers[i].getAttribute("link");
      var title = markers[i].getAttribute("title");
      var image = markers[i].getAttribute("img");
      var location = markers[i].getAttribute("shortloc");
      var minbed = markers[i].getAttribute("minbed");
      var maxbed = markers[i].getAttribute("maxbed");
      // create the marker //
      var marker = createMarker(point,link,title,image,location,minbed,maxbed);
    }
  });
}

/* ====================== InfoWindow ======================  */
var infowindowRad = new google.maps.InfoWindow({
 size: new google.maps.Size(150,50)
});
